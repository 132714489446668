import React, { useEffect, useRef } from 'react';
import docs from '../../assets/images/graphics/docs.png';
import { useInView } from '../../utils/useInView';

const DocumentationPage: React.FC = () => {

    const aiLevels = [
        {
            level: "AI Novice",
            questions: [
                {
                    question: "What is AI?",
                    resources: [
                        "Artificial Intelligence: A Modern Approach (4th Edition) by Stuart Russell and Peter Norvig (2020)",
                        "Life 3.0: Being Human in the Age of Artificial Intelligence by Max Tegmark (2017)"
                    ]
                },
                {
                    question: "What are some examples of AI applications?",
                    resources: [
                        "The Hundred-Page Machine Learning Book by Andriy Burkov (2019)"
                    ]
                }
            ]
        },
        {
            level: "AI User",
            questions: [
                {
                    question: "How does AI work?",
                    resources: [
                        "Machine Learning For Absolute Beginners: A Plain English Introduction (3rd Edition) by Oliver Theobald (2021)",
                        "The Master Algorithm by Pedro Domingos (2015)"
                    ]
                },
                {
                    question: "What are the different types of AI?",
                    resources: [
                        "Artificial Intelligence: A Modern Approach (4th Edition) by Stuart Russell and Peter Norvig (2020)"
                    ]
                }
            ]
        },
        {
            level: "AI Enthusiast",
            questions: [
                {
                    question: "What are the ethical implications of AI?",
                    resources: [
                        "Weapons of Math Destruction by Cathy O'Neil (2016)",
                        "The Ethics of Artificial Intelligence and Robotics (The Stanford Encyclopedia of Philosophy)"
                    ]
                },
                {
                    question: "How is AI being used in your industry?",
                    resources: [
                        "Industry-specific reports and articles"
                    ]
                }
            ]
        },
        {
            level: "AI Practitioner",
            questions: [
                {
                    question: "How can AI be used to solve problems?",
                    resources: [
                        "Building Machine Learning Powered Applications by Emmanuel Ameisen (2020)",
                        "Designing Machine Learning Systems: An Iterative Process for Production-Ready Applications by Chip Huyen (2022)"
                    ]
                },
                {
                    question: "What are the limitations of AI?",
                    resources: [
                        "The Alignment Problem: Machine Learning and Human Values by Brian Christian (2020)"
                    ]
                }
            ]
        },
        {
            level: "AI Expert",
            questions: [
                {
                    question: "What are the latest advancements in AI?",
                    resources: [
                        "AI Index Report 2023 by Stanford University",
                        "DistilBERT Research Paper by Jacob Devlin et al. (2019)"
                    ]
                },
                {
                    question: "What are the challenges in developing and deploying AI systems?",
                    resources: [
                        "Human-Compatible: Artificial Intelligence and the Problem of Control by Stuart Russell (2019)"
                    ]
                }
            ]
        }
    ];

    const ref = useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, {
        threshold: 0.1,
        triggerOnce: true
    });

    const additionalResources = [
        { title: "AI and Data Literacy", source: "European School Education Platform" },
        { title: "AI Courses and Material", source: "AI on Demand Platform - AI4Europe EU-funded Project" },
        { title: "Schwartz's Theory of Basic Human Values", source: "Schwartz, S. H. (2012). An overview of the Schwartz theory of basic values. Online readings in Psychology and Culture, 2(1), 11" },
        { title: "Embedding values in AI by Design", source: "Ziouvelou, X., Karkaletsis, V., & Giouvanopoulou, K. (2024). Embedding values in AI by design: an integrated framework. In Smart Ethics in the Digital World: Proceedings of the ETHICOMP 2024. 21th International Conference on the Ethical and Social Impacts of ICT (pp. 239-244). Universidad de La Rioja." },
        { title: "Research Integrity and Ethics", source: "Changer EU-funded project" }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="relative w-full flex flex-col items-center justify-center z-0 questionaire-wrapper max-w-1128 m-auto mt-28 md:mt-40 lg:mt-48 mb-14 md:mb-20 lg:mb-28 px-4 lg:px-0">
            <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 mb-6 opacity-0 animate-fadeInUp">
                <h2 className="text-2xl md:text-3xl font-bold mb-4 lg:mb-8 text-main w-full text-center lg:text-left flex items-center justify-center">AI Literacy Levels</h2>
                <img src={docs} alt="Documentation" className="col-span-1 lg:col-span-2 w-full h-auto" />
            </div>
            <div ref={ref} className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-x-8">
                {aiLevels.map((level, index) => (
                    <div
                        key={index}
                        className={`mb-8 opacity-0 transition-all duration-1000 ease-out rounded-md shadow-md p-4 md:p-6 bg-neutral-50
                            ${isInView ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}
                        style={{ transitionDelay: `${index * 0.33}s` }}
                    >
                        <h4 className="text-lg md:text-xl font-bold mb-4 text-main">{level.level}</h4>
                        {level.questions.map((q, qIndex) => (
                            <div
                                key={qIndex}
                                className={`mb-4 opacity-0 transition-all duration-700 ease-out
                                    ${isInView ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}
                                style={{ transitionDelay: `${(index * 0.2) + ((qIndex + 1) * 0.1)}s` }}
                            >
                                <p className="font-semibold mb-2">{q.question}</p>
                                <ul className="list-disc pl-5">
                                    {q.resources.map((resource, rIndex) => (
                                        <li key={rIndex} className="text-xs md:text-sm">{resource}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="w-full mt-8 md:mt-12 lg:mt-16">
                <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 lg:mb-8 text-main">The assessments for values and AI Literacy were based on:</h2>
                <ol className="list-decimal pl-5 space-y-2 md:space-y-4">
                    <li>
                        Values based on Schwartz, S. H. (2012). An Overview of the Schwartz Theory of Basic Values. <a href="https://doi.org/10.9707/2307-0919.1116" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Online Readings in Psychology and Culture, 2, (1)</a>.
                    </li>
                    <li>
                        AI Literacy based on Ng et al. 2021 AI literacy framework <a href="https://www.sciencedirect.com/science/article/pii/S2666920X21000368" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Ng, D. T. K., Leung, J. K. L., Chu, S. K. W., & Qiao, M. S. (2021). Conceptualizing AI literacy: An exploratory review. Computers and Education: Artificial Intelligence, 2, 100041</a>.
                    </li>
                </ol>
            </div>
            <div className="w-full mt-8 md:mt-12 lg:mt-16">
                <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 lg:mb-8 text-main">Additional Resources</h2>
                <p className="mb-4 md:mb-6">We encourage you to explore the world of AI further. Here are some relevant resources:</p>
                <ul className="list-disc pl-5 space-y-2 md:space-y-4">
                    {additionalResources.map((resource, index) => (
                        <li key={index} className="text-sm md:text-sm">
                            <span className="font-semibold">{resource.title}</span> ({resource.source})
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
};

export default DocumentationPage;