import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCookies } from 'react-cookie';
import QuestionaireIntro from './QuestionaireIntro/QuestionaireIntro';
import './QuestionaireWrapper.css';
import { useAuthContext } from '../../utils/useAuthContext';

import Question from './Question/Question';
import QuestionaireOutro from './QuestionaireOutro/QuestionaireOutro';

const QuestionnaireWrapper = () => {
  const { user } = useAuthContext();
  const [questionnaire, setQuestionnaire] = useState(1);
  const [oldCookie, setOldCookie] = useState('');
  const [questionnaireState, setQuestionnaireState] = useState('intro');
  const [continueState, setContinueState] = useState('start');
  const [response_id, setResponseId] = useState('');
  const [cookies, setCookie] = useCookies(['response_id']);

  const createNewCookie = () => {
    // If the cookie doesn't exist, initialize response_id with a new UUID
    const newResponseId = uuidv4();
    // 1/5.3 × 10^36 chance of collision
    setResponseId(newResponseId);

    // Set the response_id cookie with an expiration date of 7 days
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);

    // Set the response_id cookie with the new UUID
    setCookie('response_id', newResponseId, { path: '/' });
  }

  // On first render, check if the response_id cookie exists - Used for saving responses even if the user closes the browser
  useEffect(() => {
    // Check if the response_id cookie exists
    if (cookies.response_id && continueState === 'start') {
      // If the cookie exists, set response_id to its value
      setResponseId(cookies.response_id);
      setContinueState('continue');
    } else {
      createNewCookie();
    }
  }, []);

  const changeQuestionState = (newMode: string, continueFlag: boolean = false) => {
    if (continueFlag) {
      setQuestionnaireState(newMode);
    } else {
      // To be added - a read cookie should be ideally different from the one that is set
      setOldCookie(cookies.response_id);
      createNewCookie();
      setQuestionnaireState(newMode);
    }
  };
  console.log(questionnaireState)

  return (

    <div className="px-4  lg:px-0 w-full">
      <div className={`
    relative w-full flex items-center justify-center z-0 questionaire-wrapper 
    max-w-[1128px] mx-auto 
    mt-8 sm:mt-12 md:mt-24 mb-8 sm:mb-12 md:mb-20 lg:mb-28 
    rounded-md shadow-md bg-neutral-200 
    ${questionnaireState === 'end' ? 'min-h-screen lg:h-auto' : 'min-h-[76vh]'}
  `}>
        <div className={`
      w-full px-4 sm:px-6 md:px-8
      ${questionnaireState === 'end' ? 'max-w-full lg:max-w-[900px]' : 'max-w-full sm:max-w-[90vw] lg:max-w-[510px]'}
    `}>
          {questionnaireState === 'intro' && (
            <QuestionaireIntro
              questionnaireId={questionnaire}
              continueS={continueState}
              onButtonClick={changeQuestionState}
            />
          )}
          {questionnaireState === 'question' && (
            <Question
              continueState={continueState}
              questionaireId={questionnaire}
              responseId={response_id}
              onEnd={changeQuestionState}
            />
          )}
          {questionnaireState === 'end' && (
            <QuestionaireOutro
              questionnaireId={questionnaire}
              responseId={oldCookie}
              questionnaireState={questionnaireState}
            />
          )}
        </div>
        {user && (
          <div className='
        cursor-pointer z-10 absolute 
        bottom-4 right-4 sm:bottom-6 sm:right-6 md:bottom-8 md:right-8 lg:bottom-10 lg:right-12 
        py-2 px-4 sm:py-3 sm:px-6 md:py-4 md:px-8 lg:py-10 lg:px-12 
        text-sm sm:text-base
      ' onClick={() => window.location.href = '/admin'}>
            Enter Admin
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionnaireWrapper;