import React, { useState } from 'react';
import './EmailQuestion.css';
import QuestionFooter from '../QuestionFooter/QuestionFooter';

interface EmailQuestionProps {
  sendEmail: (email: string) => void;

  className?: string;
  submitButtonText?: string;
  placeholder?: string;
  deleteAnswer: () => void;
  lastAnswerId?: number | undefined;
}

const EmailQuestion: React.FC<EmailQuestionProps> = ({ sendEmail, placeholder, className, submitButtonText, deleteAnswer, lastAnswerId }) => {
  const [email, setEmail] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() !== '' && emailRegex.test(email)) {
      sendEmail(email);

    } else {
      setIsInvalidEmail(true);
    }
  };

  const handleSkip = () => {
    const defaultEmail = 'skipped@aipoliteia.eu';
    sendEmail(defaultEmail);

  };

  return (
    <div className={`flex ${className}`}>
      {isInvalidEmail ? (
        <p className="text-red-500 text-xs font-semibold absolute left-[19.7%] bottom-[22.3%]">Please enter a valid email address</p>
      ) : null}
      <input
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder={placeholder ? placeholder : "Please enter your email address"}
        className={`w-full p-2 mb-3 border rounded text-black no-outline ${isInvalidEmail ? 'border-red-500' : 'border-copernicus_orange'} focus:outline-none active:outline-none`}
      />
      <div>
        <QuestionFooter
          onClickNext={handleSubmit}
          onClickBack={deleteAnswer}
          onClickSkip={handleSkip}
          isFirstQuestion={lastAnswerId === undefined}
        />
      </div>
    </div>
  );
};

export default EmailQuestion;