import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthProvider } from './utils/AuthContext';
import PrivateRoute from './utils/PrivateRoute';

import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';

import Home from './Pages/Home/Home';
import LoginPage from './Pages/Login/LoginPage';
import Admin from './Pages/Admin/Admin';

import CreateCall from './Pages/AdminView/CreateCall/CreateCall';
import AssessmentPage from './Pages/AssessmentPage/AssessmentPage';
import DocumentationPage from './Pages/DocumentationPage/DocumentationPage';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/create-call" element={<CreateCall />} /> */}
          
          <Route path='/assesment' element={<AssessmentPage />} />
          <Route path='/documentation' element={<DocumentationPage />} />

          <Route path="/admin"
            element={
              <PrivateRoute authenticationPath="/login">
                <Admin />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
        <Footer />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
