import React, { useState, useEffect } from 'react';
import { ACTIVE_URL } from '../../../constants';
import EmailQuestion from '../Question/EmailQuestion/EmailQuestion';
import RadarChart from '../../../utils/RadarChart/RadarChart';
// import NumberCounter from '../../utils/NumberCounter/NumberCounter';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../@/components/ui/accordion";
import { Link } from 'react-router-dom';



interface QuestionaireOutroProps {
  questionnaireId: number;
  responseId: string;
  questionnaireState: string;
}

interface spiderChartDataProps {
  labels: string[],
  values: number[]
}

const QuestionaireOutro: React.FC<QuestionaireOutroProps> = ({ questionnaireId, responseId, questionnaireState }) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false);
  const [complianceScore, setComplianceScore] = useState<number>(0);
  const [valuesChartData, setValuesChartData] = useState<spiderChartDataProps>({ labels: [], values: [] });
  const [literacyChartData, setLiteracyChartData] = useState<spiderChartDataProps>({ labels: [], values: [] });
  const [literacyTag, setLiteracyTag] = useState<{ tag: string, description: string } | null>(null);

  const sendEmail = async (email: string) => {
    console.log(responseId)
    try {

      const response = await fetch(`${ACTIVE_URL}/api/set_response_email/${responseId}/email/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'email': email }),
      });
      if (response.ok) {
        setEmailSubmitted(true);
      } else {
        console.error('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  }



  const downloadResponse = async () => {

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      const response = await fetch(`${ACTIVE_URL}/api/user_download_response/${responseId}/`, requestOptions);
      if (response.ok) {
        const contentDisposition = response.headers.get('content-disposition');
        const filename = contentDisposition
          ? contentDisposition.split('filename=')[1]
          : 'RTAI_Assessment_Response_Overview.csv';

        const blob = await response.blob();
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      } else {
        console.error('Failed to fetch questions');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  }

  const getLabelScores = async (category: string) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const encodedCategory = encodeURIComponent(category);
    try {
      const response = await fetch(`${ACTIVE_URL}/api/get_values_matrix/${responseId}/${encodedCategory}/`, requestOptions);
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        const lbls = data.labels;
        const vals = data.values;
        return { labels: lbls, values: vals };
        setValuesChartData({ labels: lbls, values: vals });
      } else {
        console.error('Failed to fetch scores');
      }
    } catch (error) {
      console.error('Error fetching scores:', error);
    }
  }
  useEffect(() => {
    const fetchAndSetValuesScores = async () => {
      const result = await getLabelScores('PERSONAL VALUES');
      if (result) {
        setValuesChartData(result);
      } else {
        console.error('Failed to retrieve chart data');
      }
    };

    const fetchAndSetLiteracyScores = async () => {
      const result = await getLabelScores('ΑΙ LITERACY');
      if (result) {
        setLiteracyChartData(result);
      } else {
        console.error('Failed to retrieve chart data');
      }
    };

    fetchAndSetValuesScores();
    fetchAndSetLiteracyScores();

  }, [responseId]);

  useEffect(() => {

    if (literacyChartData.values.length > 0) {
      const tag = assignAiLiteracyTag(literacyChartData.values);
      setLiteracyTag(tag);
    }
  }, [literacyChartData]);

  const vChData = {
    labels: valuesChartData.labels,
    datasets: [
      {
        label: 'Score',
        data: valuesChartData.values,
        backgroundColor: 'rgba(20,83,153)',
        borderColor: 'rgba(20,83,153)',
        borderWidth: 1,
        fill: true,
        pointBackgroundColor: 'rgba(20,83,153, 0.7)',
        pointBorderColor: 'rgba(20,83,153, 0.7)',
        pointHoverBackgroundColor: 'rgba(20,83,153, 1)',
        pointHoverBorderColor: 'rgba(247,107,0, 1)'
      },
    ],
  };

  const lChData = {
    labels: literacyChartData.labels,
    datasets: [
      {
        label: 'Score',
        data: literacyChartData.values,
        backgroundColor: 'rgba(20,83,153)',
        borderColor: 'rgba(20,83,153)',
        borderWidth: 1,
        fill: true,
        pointBackgroundColor: 'rgba(20,83,153, 0.7)',
        pointBorderColor: 'rgba(20,83,153, 0.7)',
        pointHoverBackgroundColor: 'rgba(20,83,153, 1)',
        pointHoverBorderColor: 'rgba(247,107,0, 1)'
      },
    ],
  };

  function calculateAverage(numbers: number[]): number {
    if (numbers.length === 0) {
      throw new Error("The list of numbers cannot be empty.");
    }

    const sum = numbers.reduce((acc, num) => acc + num, 0);
    return sum / numbers.length;
  }

  const aiLiteracyTags: { [key: string]: { description: string } } = {
    'AI Novice': { description: 'New to AI, just starting to explore basic functions and applications. May not yet understand ethical implications or how AI is developed.' },
    'AI User': { description: 'Comfortable using various AI tools and applications, but may have a limited understanding of the technology\'s inner workings or ethical complexities.' },
    'AI Enthusiast': { description: 'Actively interested in AI, possesses decent knowledge across the board. Might experiment with AI tools, understand core concepts, and have awareness of ethical considerations.' },
    'AI Practitioner': { description: 'Works with AI applications regularly, applies AI knowledge in various scenarios, and has a solid understanding of the ethical implications. May not yet be involved in the development or critical evaluation of AI systems.' },
    'AI Expert': { description: 'Deep understanding of AI across all levels – functions, applications, development, and ethics. Could be a researcher, developer, or someone who critically evaluates and works with AI systems regularly.' },
    'AI Ethicist': { description: 'Specializes in the ethical and societal implications of AI. Possesses a comprehensive understanding of AI\'s potential benefits and risks, and advocates for responsible AI use. May be involved in policy-making or advising organizations on ethical AI practices.' },
    'AI Developer': { description: 'Likely involved in the creation and evaluation of AI systems. Possesses strong technical skills and a deep understanding of AI concepts and applications. Demonstrates a high level of proficiency in designing, developing, and troubleshooting AI models.' },
    'AI Visionary': { description: 'A true AI leader and innovator. Excels in all aspects of AI literacy – technical knowledge, practical application, ethical understanding, and creative vision. Drives AI advancement while actively considering and addressing the broader implications of AI for society.' }
  };

  function assignAiLiteracyTag(scores: number[]): { tag: string, description: string } {

    const averageScore = calculateAverage(scores);

    let tag: string;

    if (averageScore >= 0 && averageScore < 2) {
      tag = 'AI Novice';
    } else if (averageScore >= 2 && averageScore < 3) {
      tag = 'AI User';
    } else if (averageScore >= 3 && averageScore < 4) {
      tag = 'AI Enthusiast';
    } else if (averageScore >= 4 && averageScore < 4.5) {
      tag = 'AI Practitioner';
    } else if (averageScore >= 4.5 && averageScore < 5) {
      tag = 'AI Expert';
    } else if (averageScore === 5) {
      tag = 'AI Visionary';
    } else {
      throw new Error("Average score is out of bounds.");
    }

    return { tag, description: aiLiteracyTags[tag].description };
  }

  return (

    <div className='text-center question-slide relative flex flex-col h-full pt-10'>
      <div className='text-center flex items-center justify-center flex-col'>
        <h2 className='text-4xl font-bold mb-4 text-main'>Thanks for Diving Deep! </h2>
        <p className='my-2 sm:max-w-[90vw] lg:max-w-[510px]  text-neutral-600'>You've reached the end of your values and AI literacy journey. Take a moment to reflect on your answers.</p>
      </div>
      <div className='grid grid-cols-1 h-full w-full py-10'>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4  h-auto w-full'>
          <div className='relative w-full flex items-center justify-center flex-col '>
            <h3 className='text-2xl text-main'>Overview of my values</h3>
            <RadarChart data={vChData} key="radarChart1" />
          </div>
          <div className='relative w-full flex items-center justify-center flex-col'>
            <h3 className='text-2xl text-main'>AI Literacy Score</h3>
            <RadarChart data={lChData} key="radarChart2" />
          </div>
        </div>

        <div className='grid grid-cols-1 h-full w-full py-10 '>
          <h3 className='text-2xl text-main mb-4'>You are an {literacyTag?.tag}!</h3>
          <div className='sm:max-w-[90vw] lg:max-w-[510px] m-auto'>{literacyTag?.description}</div>
          <Link to='/documentation' target="_blank" className='text-main underline mt-4 hover:underline'>Learn more about AI literacy levels</Link>
        </div>

        <div className='relative  w-full text-start flex-col max-w-[510px] m-auto mb-8'>
          <h3 className='text-2xl mb-4 text-center text-main'>Explanation of values</h3>
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger>Self-Direction</AccordionTrigger>
              <AccordionContent>
                This value refers to the freedom and autonomy in choosing technologies and approaches in work.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger>Stimulation</AccordionTrigger>
              <AccordionContent>
                This value refers to the desire for intellectually demanding and innovative tasks.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger>Hedonism</AccordionTrigger>
              <AccordionContent>
                This value refers to the enjoyment derived from the process of programming.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-4">
              <AccordionTrigger>Achievement</AccordionTrigger>
              <AccordionContent>
                This value refers to the importance of meeting deadlines and successfully completing projects.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-5">
              <AccordionTrigger>Power</AccordionTrigger>
              <AccordionContent>
                This value refers to the importance of influence and control over technical decisions and project outcomes.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-6">
              <AccordionTrigger>Security</AccordionTrigger>
              <AccordionContent>
                This value refers to the importance of stable and secure employment as a programmer.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-7">
              <AccordionTrigger>Conformity</AccordionTrigger>
              <AccordionContent>
                This value refers to the importance of adapting to the rules and expectations of the programmer community and the organization.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-8">
              <AccordionTrigger>Tradition</AccordionTrigger>
              <AccordionContent>
                This value refers to the importance of adhering to proven methodologies and established practices.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-9">
              <AccordionTrigger>Benevolence</AccordionTrigger>
              <AccordionContent>
                This value refers to using programming skills to have a positive impact on society.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-10">
              <AccordionTrigger>Universalism</AccordionTrigger>
              <AccordionContent>
                This value refers to working on projects that align with personal values and contribute to larger goals.
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>


        <div className='relative  w-full text-start flex-col sm:max-w-[90vw] lg:max-w-[510px] m-auto mb-8'>
          <h3 className='text-2xl mb-4 text-center text-main'>Explanation of AI Literacy Score</h3>
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger>Know & understand AI</AccordionTrigger>
              <AccordionContent>
                Know the basic functions of AI and how to use AI applications.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger>Use & Apply AI</AccordionTrigger>
              <AccordionContent>
                Applying AI knowledge, concepts and applications in different scenarios.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger>Evaluate & create AI</AccordionTrigger>
              <AccordionContent>
                Higher-order thinking skills (e.g., evaluate, appraise, predict, design) with AI applications.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-4">
              <AccordionTrigger>AI ethics</AccordionTrigger>
              <AccordionContent>
                Human-centered considerations (e.g., fairness, accountability, transparency, ethics, safety).
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
        <div className='relative w-full text-start flex-col sm:max-w-[90vw] lg:max-w-[510px] m-auto mb-8'>
          <h3 className='text-2xl mb-4 text-center text-main'>Ready to Learn More?</h3>
          <p className='mb-4'>We encourage you to explore the world of AI further. Here are some relevant resources:</p>
          <ul className='list-disc pl-5'>
            <li>
              <a href="https://school-education.ec.europa.eu/en/insights/news/ai-and-data-literacy-key-skills-data-driven-world" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                AI and Data Literacy
              </a> (European School Education Platform)
            </li>
            <li>
              <a href="https://www.ai4europe.eu/education" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                AI Courses and Material
              </a> (AI on Demand Platform - AI4Europe EU-funded Project)
            </li>
            <li>
              <a href="https://school-education.ec.europa.eu/en/insights/news/ai-and-data-literacy-key-skills-data-driven-world" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                Schwartz's Theory of Basic Human Values
              </a> (Schwartz, S. H. (2012). An overview of the Schwartz theory of basic values. <i>Online readings in Psychology and Culture</i>, <i>2</i>(1), 11)
            </li>
            <li>
              <a href="https://dialnet.unirioja.es/descarga/articulo/9333582.pdf" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                Embedding values in AI by Design
              </a> (Ziouvelou, X., Karkaletsis, V., & Giouvanopoulou, K. (2024). Embedding values in AI by design: an integrated framework. In <i>Smart Ethics in the Digital World: Proceedings of the ETHICOMP 2024. 21th International Conference on the Ethical and Social Impacts of ICT</i> (pp. 239-244). Universidad de La Rioja.)
            </li>
            <li>
              <a href="https://changer-project.eu/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                Research Integrity and Ethics
              </a> (Changer EU-funded project)
            </li>
          </ul>
        </div>
        <div className='relative w-full text-start flex-col sm:max-w-[90vw] lg:max-w-[510px] m-auto mb-8'>
          <h3 className='text-2xl mb-4 text-center text-main'>The assessments for values and AI Literacy were based on:</h3>
          <ol className='list-decimal pl-5'>
            <li>
              Values based on Schwartz, S. H. (2012). An Overview of the Schwartz Theory of Basic Values. <a href="https://doi.org/10.9707/2307-0919.1116" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Online Readings in Psychology and Culture, 2, (1)</a>.
            </li>
            <li>
              AI Literacy based on Ng et al. 2021 AI literacy framework <a href="https://www.sciencedirect.com/science/article/pii/S2666920X21000368" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Ng, D. T. K., Leung, J. K. L., Chu, S. K. W., & Qiao, M. S. (2021). Conceptualizing AI literacy: An exploratory review. Computers and Education: Artificial Intelligence, 2, 100041</a>.
            </li>
          </ol>
        </div>

        <div className='flex w-full items-center justify-center mb-4 mt-1'>
          <button
            onClick={downloadResponse}
            className="px-4 py-2 bg-main text-white font-semibold rounded shadow-md transition-colors hover:bg-frisco_purple_light focus:outline-none"
          >
            Download CSV
          </button>
        </div>



      </div>
    </div>
  );

};
export default QuestionaireOutro;