import React, { useContext, FormEvent, useState, useEffect } from 'react';
import AuthContext from '../../../utils/AuthContext';
import { IonIcon } from '@ionic/react';
import { eye, eyeOff, informationCircleOutline, arrowBack } from 'ionicons/icons';



const UserLogin = () => {
    const authContext = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [resetEmail, setResetEmail] = useState('');

    const handleLoginFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        await authContext?.loginUser(username, password);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const forgotPasswordSubmit = async (e: FormEvent) => {
        e.preventDefault();
        console.log('forgot password submit');
    }

    if (forgotPassword) {
        return (
            <form onSubmit={forgotPasswordSubmit} className='py-20 px-16 shadow-xl w-full max-w-650 z-10 rounded-xl'>
                <h3 className='select-none text-2xl mb-6'>Reset Your Password</h3>
                <label htmlFor="email" className="select-none text-lg">Account Email</label>
                <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    value={resetEmail}
                    className={`appearance-none border-l-0 border-r-0 border-t-0 bg-transparent border-b w-full p-2 mb-4 border-2 rounded text-black no-outline focus:outline-none active:outline-none mt-1`} onChange={(e) => setUsername(e.target.value)}
                />
                <p className='text-sm text-neutral-500 select-none'>
                    <IonIcon icon={informationCircleOutline} className=' ionicon-large-transform mr-1' />
                    A link to reset your password will be sent to your email.
                </p>
                <div className='flex justify-between items-center mt-6'>
                    <input className="px-6 py-2 bg-main text-white cursor-pointer  text-m font-semibold rounded transition-colors shadow-md hover:bg-blue-600 focus:outline-none" type="submit" value={'Reset Password'} />
                    <p className='select-none text-sm text-main cursor-pointer hover:underline' onClick={() => setForgotPassword(false)}>
                        <IonIcon icon={arrowBack} className='text-main ionicon-large-transform mr-1' />
                        Back to Login
                    </p>
                </div>

            </form>
        )
    }

    return (

        <form onSubmit={handleLoginFormSubmit} className='py-20 px-16 w-full max-w-650 shadow-xl z-10 rounded-xl'>
            <h3 className='select-none text-2xl mb-6'>Login</h3>
            <label htmlFor="username" className="select-none text-lg">Username</label>
            <input
                type="text"
                name="username"
                placeholder="Enter Your Username"
                value={username}
                className={`appearance-none border-l-0 border-r-0 border-t-0 bg-transparent border-b w-full p-2 mb-4 border-2 rounded text-black no-outline focus:outline-none active:outline-none mt-1`} onChange={(e) => setUsername(e.target.value)}
            />
            <label htmlFor="password" className="select-none text-lg">Password</label>
            <input
                type={showPassword ? "text" : "password"}
                className={`appearance-none border-l-0 border-r-0 border-t-0 bg-transparent border-b w-full p-2 mb-4 border-2 rounded text-black no-outline focus:outline-none mt-1 active:outline-none`}
                name="password"
                placeholder="Enter Your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <IonIcon
                className="absolute  text-2xl text-neutral-400 cursor-pointer hover:text-neutral-500 duration-75"
                style={{ transform: 'translate(-180%, 40%)' }}
                onClick={togglePasswordVisibility}
                icon={showPassword ? eyeOff : eye}
            />
            <div className='flex justify-between items-center'>
                <input className="px-6 py-2 bg-main text-white cursor-pointer mt-1 text-m font-semibold rounded transition-colors shadow-md hover:bg-blue-600 focus:outline-none" type="submit" value={'Login'} />
                <div className='text-sm text-main hover:underline cursor-pointer select-none' onClick={() => { setForgotPassword(true) }}>I forgot my password</div>
            </div>


        </form>
    )

}

export default UserLogin;