import React, { useState, useEffect } from 'react';
import defaultProfileImg from '../../../../assets/images/defaults/profile-icon.png';
import womanProfileImg from '../../../../assets/images/defaults/avatar_2.jpg'
import './AdminAvatar.css';

import { useAuthContext } from '../../../../utils/useAuthContext';
import { useNavigate } from 'react-router-dom';

interface adminAvatarProps {
    username: string;
    fullname: string;
    userRole: string;
    avatar?: 'default' | 'woman' | 'man';
    place?: 'admin-header' | 'navbar';
}

const AdminAvatar: React.FC<adminAvatarProps> = ({username, fullname, userRole, avatar, place}) => {
    const { authTokens } = useAuthContext();
    const [activeAvatar, setActiveAvatar] = useState(avatar || 'default');
    const context = useAuthContext();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        username: username,
        fullname: fullname,
        userRole: userRole,
    });

    return (
        <div className='admin-avatar-wrapper'>
            <div className='admin-avatar-img-wrapper'>
                <img src={activeAvatar == 'woman' ? womanProfileImg : defaultProfileImg} alt="Profile Image" />
            </div>
            <div className='admin-avatar-info'>
                <div className='admin-avatar-info--main'>
                    {fullname}
                </div>
                <div className='admin-avatar-info--secondary'>
                    {userRole}
                </div>
            </div>
        </div>
    );
};

export default AdminAvatar;