import React from 'react';

interface QuestionFooterProps {
    onClickNext: () => void;
    onClickBack: () => void;
    onClickSkip?: () => void;
    isFirstQuestion?: boolean;
}

const QuestionFooter: React.FC<QuestionFooterProps> = ({ onClickNext, onClickBack, onClickSkip, isFirstQuestion }) => {
    return (
        <div className="flex w-full justify-between items-center gap-8">
            {!isFirstQuestion ? (
                <button onClick={onClickBack} className="px-4 py-2 bg-neutral-500 text-white font-semibold rounded transition-colors shadow-md hover:bg-neutral-600 focus:outline-none">
                    Back
                </button>
            ) : (
                <div className="flex-grow" />
            )}
            <div className='flex gap-4'>
                {onClickSkip && (
                    <button onClick={onClickSkip} className="px-4 py-2 bg-neutral-400 text-white font-semibold rounded transition-colors shadow-md hover:bg-neutral-500 focus:outline-none">
                        Skip
                    </button>
                )}
                <button onClick={onClickNext} className="px-4 py-2 bg-main text-white font-semibold rounded transition-colors shadow-md hover:bg-frisco_purple_light focus:outline-none">
                    <p>Next</p>
                </button>
            </div>


        </div>
    );
};

export default QuestionFooter;