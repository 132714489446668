import React, {useContext} from 'react';
import './AdminSideBar.css';
import img from '../../../assets/images/logos/copernicuslogo.png';
import logo from '../../../assets/images/logos/logo_vert.png';
import { IonIcon } from '@ionic/react';
import AuthContext from '../../../utils/AuthContext';
import { homeSharp, documentText, analytics, clipboardSharp, settings, logOut, people } from 'ionicons/icons';

interface AdminSideBarProps {
    activeScreen: string;
    setActiveScreen: (screen: string) => void;
}

const AdminSideBar: React.FC<AdminSideBarProps> = ({ activeScreen, setActiveScreen }) => {
    let { logoutUser } = useContext(AuthContext) as any;

    const handleMenuItemClick = (screen: string) => {
        setActiveScreen(screen);
    };

    return (
        <div className='admin-sidebar overflow-y-auto'>
            <a href='/' className="flex items-center justify-center admin-logo mt-2 mb-2 py-10 w-full">
                <img src={logo} alt="" />
            </a>
            <div className='sidebar-menu'>
                <div className={`sidebar-menu-item ${activeScreen === 'Dashboard' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Dashboard')}>
                    <IonIcon icon={homeSharp} className="text-blue-800 font-semibold ion-icon-item" /> Dashboard
                </div>
                <div className={`sidebar-menu-item ${activeScreen === 'Assesments' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Assesments')}>
                    <IonIcon icon={documentText} className="text-blue-800 font-semibold ion-icon-item" /> Assesments
                </div>

                <div className={`sidebar-menu-item ${activeScreen === 'Results' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Results')}>
                    <IonIcon icon={clipboardSharp}className="text-blue-800 font-semibold ion-icon-item" /> Results
                </div>
                <div className={`sidebar-menu-item ${activeScreen === 'Orgs&users' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Orgs&users')}>
                    <IonIcon icon={people} className="text-blue-800 font-semibold ion-icon-item" /> Organizations & Users
                </div>
                <div className={`sidebar-menu-item ${activeScreen === 'Analytics' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Analytics')}>
                    <IonIcon icon={analytics} className="text-blue-800 font-semibold ion-icon-item" /> Platform Analytics
                </div>
            </div>
            <div className={`sidebar-menu-item logout ${activeScreen === 'Settings' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Settings')}>
                <IonIcon icon={settings} className="text-blue-800 font-semibold ion-icon-item" /> Settings
            </div>
            <div className='sidebar-menu-item logout' onClick={logoutUser}>
                <IonIcon icon={logOut} className="text-blue-800 font-semibold ion-icon-item" /> Logout
            </div>
        </div>
    )
};

export default AdminSideBar;