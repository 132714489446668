import React from 'react';

import footerImg from '../../../assets/images/graphics/footer_img.png';
import euFlag from '../../../assets/images/logos/eu-flag.png';

const FooterBottom: React.FC = () => {
    return (
        <div className='w-full'>
            <div className='bg-background_blue text-white py-8 px-4 md:px-8 lg:px-16'>
                <div className='max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between'>
                    <div className='flex flex-col md:flex-row items-center mb-8 md:mb-0'>
                        <img src={euFlag} alt="EU Flag" className='h-16 mb-4 md:mb-0 md:mr-8' />
                        <p className='text-sm text-center md:text-left'>
                            <div className='mb-2'>
                            This project is part of the AI Politeia Lab, IIT, NCSR Demokritos
                            </div>
                            <div className='text-sm'>
                            &copy; 2024 - Terms of Service and Privacy Policy
                            </div>

                        </p>

                    </div>
                    <img src={footerImg} alt="Footer Image" className='h-32 md:h-48' />
                </div>

            </div>
        </div>
    );
};

export default FooterBottom;