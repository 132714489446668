import React, { useContext, FormEvent, useState, useEffect } from 'react';
import UserLogin from '../../Components/UserStateManagement/UserLogin/UserLogin';
import UserRegistration from '../../Components/UserStateManagement/UserRegistration/UserRegistration';
import AuthContext from '../../utils/AuthContext';
import { Navigate } from 'react-router-dom';
import './LoginPage.css';

const LoginPage: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [openTab, setOpenTab] = useState('login');
  

  if (authContext?.user && authContext?.authTokens) {
    return <Navigate to="/admin" />;
  }



  return (
    <div className='py-44 flex flex-col items-center justify-center bg-neutral-50'>
      <div className=' flex justify-around view-toggle px-16 pt-10 overflow-hidden'>
      <div 
          className={`select-none cursor-pointer py-6 w-72 mr-4 border-t border-l border-r z-0 flex justify-center rounded-t-xl login-toggle-item ${openTab === 'register' ? 'bg-blue-500 text-white' : 'text-main bg-neutral-50' }`}
          onClick={() => setOpenTab('login')}
        >
          Existing User? Please Login
        </div>
      <div 
        className={`select-none cursor-pointer py-6 w-72 ml-4 border-t border-l border-r z-0 flex justify-center rounded-t-xl login-toggle-item ${openTab === 'login' ? 'bg-main text-white' : 'text-main bg-neutral-50' }`}
        onClick={() => setOpenTab('register')}
      >
          New user? Please register
        </div>

      </div>
      {openTab === 'login' ? <UserLogin/> : <UserRegistration/>}

    </div>
  );
};

export default LoginPage;