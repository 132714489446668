import React, { useContext, useState, useEffect } from 'react';
import logo from '../../assets/images/logos/logo_vert.png';
import { Link } from 'react-router-dom';
import AuthContext from '../../utils/AuthContext';
import './Navbar.css';
import AdminAvatar from '../Admin/AdminMainHeader/AdminAvatar/AdminAvatar';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../utils/useAuthContext';
import { ACTIVE_URL } from '../../constants';
import { useNavigate } from 'react-router-dom';
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "../../@/components/ui/drawer"
import { Sling as Hamburger } from 'hamburger-react';

const Navbar: React.FC = () => {
    const authContext = useContext(AuthContext);
    const { pathname } = useLocation();
    const { authTokens } = useAuthContext();
    const context = useAuthContext();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        username: '',
        fullname: '',
        userRole: '',
    });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!authTokens || !authTokens.access) {
                    return;
                }
                const response = await fetch(`${ACTIVE_URL}/api/get_user_info/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authTokens.access}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserData({
                        username: data.username,
                        userRole: data.status,
                        fullname: data.fullname,
                    });
                } else {
                    console.error('Failed to fetch data:', response.status, response.statusText);
                    if (response.status === 401) {
                        context.logoutUser();
                        navigate('/login');
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [authTokens]);

    if (pathname === '/admin') {
        return null;
    }

    const handleLinkClick = () => {
        setIsDrawerOpen(false);
    };

    const NavLinks = () => (
        <>
            <Link to="/" onClick={handleLinkClick} className='hover:text-white hover:bg-background_blue py-0.5 px-6 rounded-full font-bold text-gray-700'>Home</Link>
            <Link to='/documentation' onClick={handleLinkClick} className='hover:text-white hover:bg-background_blue py-0.5 px-6 rounded-full font-bold text-gray-700'>Documentation</Link>
            <Link to="/assesment"
                onClick={handleLinkClick}
                className='text-white bg-background_blue py-4 px-16 rounded-full shadow-2xl'
                style={{ boxShadow: `0 2px 4px #3f98fc` }} >
                Assessment Tool
            </Link>
        </>
    );

    return (
        <div className='w-full flex  justify-center h-20 lg:h-84 px-4 lg:px-0'>
            <div className='w-full max-w-7xl flex justify-between items-center lg:absolute lg:w-[1128px] py-[20px]'>
                <Link to="/">
                    <img src={logo} alt="" className='h-[40px] lg:h-20' />
                </Link>

                {/* Desktop Navigation */}
                <div className='hidden lg:flex gap-10 items-center'>
                    <NavLinks />
                </div>

                {/* Mobile Navigation */}
                <div className='lg:hidden'>
                    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
                        <DrawerTrigger asChild>
                            <div className="cursor-pointer ml-[-10px] scale-[60%] rounded-full">
                                <Hamburger easing="ease-in" color='black' label="Show menu" toggled={isDrawerOpen} />
                            </div>
                        </DrawerTrigger>
                        <DrawerContent className='h-[80vh] bg-neutral-50'>
                            <DrawerHeader>
                                <DrawerTitle>Menu</DrawerTitle>
                            </DrawerHeader>
                            <div className="px-4 my-6 py-2 flex flex-col space-y-6 text-center justify-center h-full ">
                                <NavLinks />
                            </div>
                            <DrawerFooter>
                                <DrawerClose asChild>
                                    <button className="w-full text-white bg-neutral-500 py-2 px-4 rounded-full shadow-md">
                                        Close Menu
                                    </button>
                                </DrawerClose>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </div>
            </div>
        </div>
    );
};

export default Navbar;