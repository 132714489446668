import React from "react";
import QuestionnaireWrapper from "../../Components/Questionnaire/QuestionnaireWrapper";


const AssessmentPage = () => {

    return (
        <div>
            <QuestionnaireWrapper />
        </div>
    )
}

export default AssessmentPage;