import React, { useState, useEffect } from 'react';
import { IonIcon } from '@ionic/react';
import { ACTIVE_URL } from '../../../constants';

interface QuestionaireIntroProps {
  continueS: string;
  questionnaireId: number;
  onButtonClick: (newMode: string, continueFlag: boolean) => void;
}

const QuestionaireIntro: React.FC<QuestionaireIntroProps> = ({ continueS, questionnaireId, onButtonClick }) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    fetch(`${ACTIVE_URL}/api/questionnaire/${questionnaireId}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setTitle(data.title);
        setDescription(data.description);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [questionnaireId]);

  return (
    <div className="max-w-md flex flex-col gap-6 py-6">
      <h1 className="text-4xl font-bold text-neutral-800">Assessment Tool for Personal Values and AI Literacy</h1>
      <p style={{ whiteSpace: 'pre-line' }} className='text-neutral-800'>
        <div className='mb-2'>
          Welcome to a journey of self-discovery and exploration! This short, anonymous, online questionnaire dives into two key aspects: your personal values and your understanding of Artificial Intelligence (AI).
        </div>

        <div className='mb-2'>
          By taking a few minutes to answer these questions, you'll gain valuable insights into:
        </div>
        <div className='mb-2'>
          •	<b>Your core values</b>: What truly matters to you in life? How do these values guide your decisions and interactions with technology?
        </div>

        <div className='mb-2'>
          •	<b>Your AI literacy</b>: How comfortable are you with AI? Do you understand its potential benefits and challenges?
        </div>
        <div>
          There are no right or wrong answers! We're exploring together the intersection of personal values and the rapidly developing field of AI.
        </div>


      </p>
      {continueS === 'start' ? (
        <div className="cursor-pointer z-10 px-4 py-2 bg-main text-white font-semibold rounded transition-colors shadow-md hover:bg-frisco_purple_light focus:outline-none w-min flex gap-1 items-center" onClick={() => onButtonClick('question', false)}>Start <IonIcon icon="arrow-forward-outline" /></div>
      ) : (
        <div className='flex justify-between dual-btn'>
          <div className="cursor-pointer z-10 px-4 py-2 bg-main text-white font-semibold rounded transition-colors shadow-md hover:bg-blue-600 focus:outline-none" onClick={() => onButtonClick('question', true)}>Continue <IonIcon style={{ 'transform': 'translateY(3px)' }} icon="arrow-forward-outline" /></div>
          <div className="py-2 z-10 px-4 z-1 cursor-pointer text-copernicus_orange" onClick={() => onButtonClick('question', false)}>Restart </div>
        </div>
      )}
    </div>
  );
}

export default QuestionaireIntro;