import React from 'react';
import HeaderImage from '../../../assets/images/graphics/home_intro.png';
import aiPolLogo from '../../../assets/images/logos/logo_AIpoliteia.png';
import iptLogo from '../../../assets/images/logos/ipt_logo.png';

const HomeHeader: React.FC = () => {
    return (
        <div className='flex w-full max-w-[1128px] items-center justify-center m-auto py-28 md:py-40 lg:py-80 px-4 lg:px-0'>
            <div className='flex flex-col lg:flex-row items-center justify-center'>
                <div className='w-full lg:w-[38%] mb-8 lg:mb-0'>
                    <h3 className='font-semibold text-lg md:text-xl pt-2 pb-4 text-center lg:text-left opacity-0 animate-fadeInUp delay-200'>
                        This project is part of the AI Politeia Lab, IIT, NCSR Demokritos
                    </h3>
                    <div className='flex justify-center lg:justify-start gap-4 mt-4'>
                        <a href="https://www.iit.demokritos.gr/" target='_blank' rel="noopener noreferrer">
                            <img src={iptLogo} alt="IPT Logo" className='h-16 md:h-20 lg:h-24 opacity-0 animate-fadeInUp delay-300' />
                        </a>
                        <a href="https://www.iit.demokritos.gr/labs/ai-politeia-lab/" target='_blank' rel="noopener noreferrer">
                            <img src={aiPolLogo} alt="AI Politeia Logo" className='h-16 md:h-20 lg:h-24 opacity-0 animate-fadeInUp delay-300' />
                        </a>
                    </div>
                </div>
                <div className='w-full lg:w-[62%] animate-fadeInUp'>
                    <img src={HeaderImage} alt="Header" className='w-full h-auto' />
                </div>
            </div>
        </div>
    );
};

export default HomeHeader;