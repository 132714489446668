import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import FooterUpper from './FooterUpper/FooterUpper';
import FooterBottom from './FooterBottom/FooterBottom';

import { useLocation } from 'react-router-dom';

const Footer: React.FC = () => {
    const { pathname } = useLocation();

    // if (pathname === '/admin' || pathname === '/login') {
    //     return null; 
    // }

    return (
        <div className='w-full'>
            <FooterUpper />
            <FooterBottom />
        </div>
    );
};

export default Footer;