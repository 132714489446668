import React, { useState, useEffect, useContext } from 'react';
import AdminAvatar from './AdminAvatar/AdminAvatar';
import PageTitle from './PageTitle/PageTitle';
import './AdminMainHeader.css';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../utils/AuthContext';

interface AdminMainHeaderProps {
    pageTitle: string;

}

const AdminMainHeader: React.FC<AdminMainHeaderProps> = ({pageTitle}) => {
    const authContext = useContext(AuthContext);
    const { pathname } = useLocation();
    const { authTokens } = useAuthContext();
    const context = useAuthContext();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        username: '',
        fullname: '',
        userRole: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!authTokens || !authTokens.access) {
                    return;
                }
                const response = await fetch(`${ACTIVE_URL}/api/get_user_info/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authTokens.access}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserData({
                        username: data.username,
                        userRole: data.status,
                        fullname: data.fullname,
                    });
                    console.log(data)
                } else {
                    console.error('Failed to fetch data:', response.status, response.statusText);
                    if (response.status === 401) {
                        context.logoutUser();
                        navigate('/login');
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [authTokens]);
    
    if (pathname === '/admin') {
        return null;
    }

    return (
        <div className='admin-main-header'>
            <PageTitle pageTitle={pageTitle} />
            <AdminAvatar avatar='woman' username={userData.username} fullname={userData.fullname} userRole={userData.userRole}/>
        </div>
    )
};

export default AdminMainHeader;