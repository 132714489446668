import React, { useContext, FormEvent, useState, useEffect } from 'react';
import AuthContext from '../../../utils/AuthContext';
import { IonIcon } from '@ionic/react';
import { ACTIVE_URL } from '../../../constants';
import { eye, eyeOff, informationCircleOutline } from 'ionicons/icons';


const UserRegistration = () => {
    const authContext = useContext(AuthContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleRegistrationFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!acceptTerms) {
            alert('Please accept the terms and conditions.');
            return;
        }
        const register = async () => {
            const response = await fetch(`${ACTIVE_URL}/api/register_user/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'first_name': firstName,
                    'last_name': lastName,
                    'email': email,
                    'username': username, 
                    'password': password 
                })
            });
            const data = await response.json();
    
            if (response.status === 200 || response.status === 201) {
                authContext?.loginUser(username, password);
            } else {
                alert(data['error']);
            }
        };
        register();
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleTermsChange = () => {
        setAcceptTerms(!acceptTerms);
    };

    return (
        <form onSubmit={handleRegistrationFormSubmit} className='py-20 px-16 shadow-xl w-full max-w-650 z-10 rounded-xl'>
            <h3 className='select-none text-2xl mb-6'>Create a new account</h3>
            <label htmlFor="firstName" className="select-none text-lg">First Name</label>
            <input
                type="text"
                name="firstName"
                placeholder="Enter Your First Name"
                value={firstName}
                className={`appearance-none border-l-0 border-r-0 border-t-0 bg-transparent border-b w-full p-2 mb-4 border-2 rounded text-black no-outline focus:outline-none active:outline-none mt-1`} onChange={(e) => setFirstName(e.target.value)}
            />
            <label htmlFor="lastName" className="select-none text-lg">Last Name</label>
            <input
                type="text"
                name="lastName"
                placeholder="Enter Your Last Name"
                value={lastName}
                className={`appearance-none border-l-0 border-r-0 border-t-0 bg-transparent border-b w-full p-2 mb-4 border-2 rounded text-black no-outline focus:outline-none active:outline-none mt-1`} onChange={(e) => setLastName(e.target.value)}
            />
            <label htmlFor="email" className="select-none text-lg">Account Email</label>
            <input
                type="email"
                name="email"
                placeholder="Enter Your Email"
                value={email}
                className={`appearance-none border-l-0 border-r-0 border-t-0 bg-transparent border-b w-full p-2 mb-4 border-2 rounded text-black no-outline focus:outline-none active:outline-none mt-1`} onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="username" className="select-none text-lg">Username</label>
            <input
                type="text"
                name="username"
                placeholder="Enter Your Username"
                value={username}
                className={`appearance-none border-l-0 border-r-0 border-t-0 bg-transparent border-b w-full p-2 mb-4 border-2 rounded text-black no-outline focus:outline-none active:outline-none mt-1`} onChange={(e) => setUsername(e.target.value)}
            />
            <label htmlFor="password" className="select-none text-lg">Password</label>
            <input
                type={showPassword ? "text" : "password"}
                className={`appearance-none border-l-0 border-r-0 border-t-0 bg-transparent border-b w-full p-2 mb-4 border-2 rounded text-black no-outline focus:outline-none mt-1 active:outline-none`}
                name="password"
                placeholder="Enter Your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <IonIcon
                className="absolute  text-2xl text-neutral-400 cursor-pointer hover:text-neutral-500 duration-75"
                style={{ transform: 'translate(-180%, 40%)' }}
                onClick={togglePasswordVisibility}
                icon={showPassword ? eyeOff : eye}
            />
            <div className="mt-4">
                <input
                    type="checkbox"
                    id="acceptTerms"
                    checked={acceptTerms}
                    onChange={handleTermsChange}
                    className="mr-2 ionicon-default-transform"
                />
                <label htmlFor="acceptTerms" className="select-none text-sm text-neutral-500">
                    <span className="select-none text-sm">I understand, accept and agree to comply with the </span>
                    <a href="#" className='text-sm underline hover:text-main duration-200'>Terms & Conditions</a>
                    <span className="select-none text-sm">, </span>
                    <a href="#" className='text-sm underline hover:text-main duration-200'>Privacy Policy</a>
                    <span className="select-none text-sm"> and </span>
                    <a href="#" className='text-sm underline hover:text-main duration-200'>Cookie Policy</a>
                    <span className='select-none text-sm'> of the AI4Copernicus project.</span>
                </label>
            </div>
            <input className="px-6 py-2 bg-main text-white cursor-pointer mt-4 text-m font-semibold rounded transition-colors shadow-md hover:bg-blue-600 focus:outline-none" type="submit" value={'Create Account'} />
        </form>
    )
}

export default UserRegistration;