import React from 'react';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { mail, logoLinkedin, logoFacebook, logoTwitter } from 'ionicons/icons';

const FooterBottom: React.FC = () => {
    return (
        <div className='w-full bg-dark_blue py-4'>
            <div className='flex flex-col gap-2 sm:flex-row items-center justify-center'>
                <a href="mailto:xeniaziouvelou@iit.demokritos.gr" className='h-85 flex flex-col rounded-full border-white px-4 items-center justify-center'>
                    <IonIcon className='text-white mb-1 text-lg' icon="mail" />
                    <p className='text-white text-sm'>Contact</p>
                </a>
                <a href="https://www.iit.demokritos.gr/labs/ai-politeia-lab/" target='_blank' className='h-85 flex flex-col rounded-full border-white px-4 items-center justify-center'>
                    <IonIcon className='text-white mb-1 text-lg' icon="link-outline" />
                    <p className='text-white text-sm'>AI Politeia</p>
                </a>
            </div>
        </div>
    );
};

export default FooterBottom;