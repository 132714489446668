import React from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);



interface RadarChartProps {
    data: {
        labels: string[];
        datasets: {
            label: string;
            data: number[];
            backgroundColor: string;
            borderColor: string;
            borderWidth: number;
        }[];
    };
}

const RadarChart: React.FC<RadarChartProps> = ({ data }) => {
    const options = {
        responsive: true,
        maintainAspectRatio: true,

        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            r: {
                beginAtZero: true,
                angleLines: {
                    display: true
                },
                ticks: {
                    display: true,
                    stepSize: 1
                }
            }
        }

    };
    return <Radar
        options={options}
        data={data}
        height={'120px'}


    />;
}

export default RadarChart;