import React, { useEffect } from 'react';

import HomeHeader from '../../Components/Home/HomeHeader/HomeHeader';
import HomeJoin from '../../Components/Home/HomeJoin/HomeJoin';

const Home: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div >
            <HomeHeader />
            <HomeJoin />
        </div>
    );
};

export default Home;