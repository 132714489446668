import React from 'react';

import HomeJoinImage from '../../../assets/images/graphics/home.png';


const HomeJoin: React.FC = () => {
    return (
        <div className='bg-gray_mid  items-center justify-center py-36 bg-blue-100 hidden sm:flex'>
            <img src={HomeJoinImage} alt="" className='rounded-2xl shadow-lg' />
        </div>
    );
};

export default HomeJoin;